<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title> Dependentes (Diretas)</template>
        <template #content>
          <div v-if="matriculasList.length > 1" class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <Dropdown
                v-model="matricula"
                :options="matriculasList"
                data-key="id"
                placeholder="Matrícula"
                style="width: 100%">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>
                      {{ slotProps.value.matricula }} -
                      {{ slotProps.value.entidadeNome }}
                    </span>
                  </div>
                </template>
                <template #option="slotProps">
                  <span class="card-desktop">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome }}
                  </span>
                  <span class="card-mobile">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome | limitadorString }}
                  </span>
                </template>
              </Dropdown>
            </div>
          </div>
          <DataTable
            stripedRows
            :value="matricula.dependentes"
            :loading="loading"
            responsiveLayout="stack">
            <template #empty> Nenhum dependente encontrado </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="nomeDependente" header="Nome"></Column>
            <Column field="sexo" header="Sexo"></Column>
            <Column field="tipoDependencia" header="Tipo"></Column>
            <Column header="Data de Nascimento">
              <template #body="{ data }">
                {{ data.dataNascimento | formatarData }}
              </template>
            </Column>
            <Column
              field="condicaoDependente"
              header="Condição Dependente"></Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import MatriculaService from '@/service/MatriculaService.js'
import PortalServidorService from '@/service/PortalServidorService'

export default {
  data() {
    return {
      dependentes: [],
      loading: false,
      servidor: {},
      matriculasList: [],
      matricula: {},
      matriculas: [],
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.portalServidorService = new PortalServidorService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.getDependentesByCpf(res[0].cpf)
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },
    getDependentesByCpf(cpf) {
      this.portalServidorService.getDependentesByCpf(cpf).then((res) => {
        if (res.length >= 1) {
          const transformedData = {}

          for (const item of res) {
            const {
              matricula,
              entidadeNome,
              id,
              nomeDependente,
              tipoDependencia,
              dataNascimento,
              sexo,
              condicaoDependente,
            } = item

            if (!transformedData[matricula]) {
              transformedData[matricula] = {
                matricula,
                entidadeNome,
                dependentes: [],
              }
            }

            transformedData[matricula].dependentes.push({
              id,
              matricula,
              nomeDependente,
              tipoDependencia,
              dataNascimento,
              sexo,
              condicaoDependente,
            })
          }

          this.matriculasList = Object.values(transformedData)
          this.matricula = this.matriculasList[0]
        }
      })
    },

    limpar() {
      this.loading = false
    },
  },
}
</script>

<style>
.div-center {
  margin: auto;
}
</style>
